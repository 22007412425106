<template>
  <div>
    <Find v-if="!showCoverageMain" />
    <CoverageMain v-if="showCoverageMain" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Find from '@/components/default/pages/manage-coverage/Find.vue'
import CoverageMain from '@/components/default/pages/manage-coverage/CoverageMain.vue'

export default {
  components: {
    Find,
    CoverageMain
  },
  computed: {
    ...mapState({
      coverage: (state) => state.manageCoverage.coverage
    }),
    showCoverageMain() {
      return !!this.coverage
    }
  }
}

</script>