<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <h2 class="text-h3 text-lg-h2 mb-4">Find Your Coverage</h2>
      <div>Enter the requested information below to locate your Coverage.</div>
    </v-responsive>
    <v-card class="pa-2 mx-auto" max-width="600" color="transparent" flat>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-text-field
            v-model="form.coverage_certificate"
            label="Coverage ID / Certificate Number"
            :rules="rules"
            solo 
            outlined
          />
          <v-text-field
            v-model="form.email_booking"
            label="Email / Booking Number"
            :rules="rules"
            solo
            outlined
          />
          <v-btn 
            block
            class="primary"
            large 
            :loading="isLoading"
            :disabled="!valid"
            @click="submit"
          >
            FIND
          </v-btn>
        </v-form>
        <v-alert
          v-if="errorMessage"
          dense
          outlined
          type="error"
          class="mt-2"
        >
          {{ errorMessage }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    valid: true,
    form: {
      coverage_certificate: '',
      email_booking: ''
    },
    rules: [(v) => !!v || 'Field is required']
  }),
  computed: {
    ...mapState({
      coverage: (state) => state.manageCoverage.coverage,
      isLoading: (state) => state.manageCoverage.isLoading,
      errorMessage: (state) => state.manageCoverage.errorMessage
    })
  },
  methods: {
    ...mapActions({
      getCoverage: 'manageCoverage/getCoverage'
    }),
    submit() {
      if (!this.valid) return
      this.getCoverage(this.form)
    }
  }
}

</script>