<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="primary"
          v-bind="attrs"
          style="width:100%"
          v-on="on"
        >
          Additional Coverage
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Purchase Additional Coverage
        </v-card-title>

        <v-card-text>
          <p class="mt-2 text--body-2">Please call {{ policyMessage }} at {{ policyPhone }} and request to add additional coverage to your booking.</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapState ({
      meta: (state) => state.microsite.meta
    }),
    policyPhoneMsg() {
      return this.meta.custom_phone_msg
    },
    policyPhone() {
      return this.meta.custom_phone
    },
    policyMessage() {
      return this.meta.attributes.FooterMessage
    },
    policyEmail() {
      return this.meta.custom_email
    }
  }
}

</script>