<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <div class="text-center">
        <h2 class="text-h3 text-lg-h2">Coverage Details</h2>
        <v-responsive max-width="800" class="mx-auto">
          <div class="text-h6 text-lg-h5 mt-4">Certificate: {{ coverage.CertificateNumber }}</div>
        </v-responsive>
      </div>
      
      <v-card justify="center" class="pa-2 mt-4">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <!--
            <div min-width="400" class="d-inline-flex font-weight-bold ma-2 mb-0">Product:</div>
            <div class="d-inline-flex ma-0">{{ coverage.ProductName }}</div>
            <v-spacer></v-spacer>
            <div min-width="400" class="d-inline-flex font-weight-bold ma-2 mb-0">Product:</div>
            <div class="d-inline-flex ma-0">{{ coverage.ProductName }}</div>
            <v-spacer></v-spacer>
            <div min-width="400" class="d-inline-flex font-weight-bold ma-2 mb-0">Product:</div>
            <div class="d-inline-flex ma-0">{{ coverage.ProductName }}</div>
            <v-spacer></v-spacer>
            <div min-width="400" class="d-inline-flex font-weight-bold ma-2 mb-0">Product:</div>
            <div class="d-inline-flex ma-0">{{ coverage.ProductName }}</div>
            <v-spacer></v-spacer>
            -->
            <v-list-item-content>
              <v-list-item-title>Product</v-list-item-title>
              <v-list-item-subtitle>{{ coverage.ProductName }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Provider</v-list-item-title>
              <v-list-item-subtitle>{{ coverage.ProviderName }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Certificate</v-list-item-title>
              <v-list-item-subtitle>{{ coverage.CertificateNumber }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Maximum Coverage</v-list-item-title>
              <v-list-item-subtitle>{{ formatMoney(coverage.Maximum_Cancellation_Benefit) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item-content>
              <v-list-item-title>Booking Number</v-list-item-title>
              <v-list-item-subtitle>{{ coverage.booking_number }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Purchase Date</v-list-item-title>
              <v-list-item-subtitle>{{ formatDate(coverage.DatePurchased) }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Cancel By Date</v-list-item-title>
              <v-list-item-subtitle>{{ formatDate(coverage.CancelByDate) }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Coverage Begins</v-list-item-title>
              <v-list-item-subtitle>{{ formatDate(coverage.Effective_Date) }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Coverage Ends</v-list-item-title>
              <v-list-item-subtitle>{{ formatDate(coverage.Termination_Date) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="4" cols="12">
            {{ /* :disabled="pastEndDate || !coverage.NewClaimToken" */ }}
            <v-btn
              :href="claimUrl"
              target="_blank"
              block
              class="primary"
            >
              Claims
            </v-btn>
          </v-col>
          <v-col sm="4" cols="12">
            <v-btn
              class="primary"
              style="width:100%"
              :href="coverage.DocLink"
            >
              Download Doc
            </v-btn>
          </v-col>
          <v-col sm="4" cols="12">
            <ModifyCoverage />
          </v-col>
        </v-row> 
      </v-card>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
import { format, parseISO } from 'date-fns'
import ModifyCoverage from './buttons/ModifyCoverage.vue'

export default {
  components: {
    ModifyCoverage
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      coverage: (state) => state.manageCoverage.coverage
    }),
    pastEndDate() {
      return new Date() > new Date(this.formatDate(this.coverage.Termination_Date))
    },
    claimUrl() {
      const path = process.env.VUE_APP_CLAIM_URL
      const token = this.coverage.NewClaimToken || ''

      return `${path}?token=${token}`
    }
  },
  methods: {
    formatDate(date) {
      const [splitDate] = date.split('T')

      // Saturday June 26, 2021
      return format(parseISO(splitDate), 'EEEE MMM dd, yyyy')
    },
    formatMoney(money) {
      return `$${money}`
    }
  }
}
</script>